import React, { useEffect, useRef, useState } from "react";
import "./Chatbot.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import "./FontSizes.scss";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { CosmicInverterService } from "../Services/CosmicInverterService";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ReactMarkdown from "react-markdown";
import EditIcon from "@mui/icons-material/Edit";
import TextToSpeech from "./TextToSpeech";
const invService = new CosmicInverterService();
const styles = {
  chatWindow: {
    display: "flex",
    width: "95%",
    height: "100%",
    flexDirection: "column",
    overflowY: "auto",
    border: "0.0625rem solid #F4F4F4",
    padding: "1rem",
    borderRadius: "0.3125rem",
    position: "relative",
  },
  profileImage: {
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "50%",
    margin: "0rem 0.625rem",
  },
  userMessage: {
    alignSelf: "flex-end",
    // background: "#FEF1B1",
    backgroundColor: "#ECF5FF",

    borderRadius: "1.5rem 0rem 1.5rem 1.5rem",
    wordBreak: "break-word",
    padding: "1rem",
    textAlign: "right",
    width: "fit-content",
  },
  messageDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginLeft: "10%",
    marginBottom: "0.625rem",
  },
  userDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginRight: "10%",
    marginBottom: "0.625rem",
  },
  aiMessage: {
    alignSelf: "flex-start",
    // background: "#D9E5F0",
    backgroundColor: "#F7F7F9",

    borderRadius: "0rem 1.5rem 1.5rem 1.5rem",
    width: "fit-content",

    wordBreak: "break-word",
    padding: "1rem",

    textAlign: "left",
  },
};
function Chatbot(props) {
  const { handleClose } = props;

  const searchedTabs = [
    {
      title: "Inverters 01",
      question: "What is the status of Inverter 1?",
    },
    {
      title: "Inverters 02",
      question: "What is the status of Inverter 2?",
    },
    {
      title: "Inverters 03",
      question: "What is the status of Inverter 3?",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(0);
  const [isPreTemplate, setIsPreTemplate] = useState(false);
  const [isNewChat, setIsNewChat] = useState(false);
  const [isChatHistoryLoaded, setIsChatHistoryLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState("");
  const [currentlyStreaming, setCurrentlyStreaming] = useState(false);
  const [userMessages, setUserMessages] = useState([
    { role: "ai", content: "" },
  ]);
  const [loadingState, setLoadingState] = useState({
    chatData: false,
    chatHistory: false,
    singleChat: false,
  });
  const [historyList, setHistoryList] = useState([]);
  const [currentPlayingId, setCurrentPlayingId] = useState(null);


  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    handleChatHistory();
  }, []);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const chatWindowRef = useRef(null);

  
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [userMessages, loadingState.chatData]);
  const handlePlayStop = (id) => {
    setCurrentPlayingId(currentPlayingId === id ? null : id);
  };
  const handleHistoryClick = (index) => {
    setActiveIndex(index);
    setUserMessages([]);
    handleSingleChatHistory(index);

    setIsPreTemplate(true);
    setIsNewChat(false);
    if (!isChatHistoryLoaded) {
      handleChatHistory();
      setIsChatHistoryLoaded(true);
    }
  };
  const handleSerchedQue = (item, index) => {
    setIsPreTemplate(true);
    setActiveSearch(index);
    sendChatMessage(item.question);
  };
  const handleHistoryDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHistoryPopoverClose = (val) => {
    setAnchorEl(null);
  };
  const handleCloseBox = () => {
    props.handleClose(false);
  };
  const handleFetchError = (error, dataType) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
        case 404:
          enqueueSnackbar(error.response?.data?.detail || "An error occurred", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        case 403:
          history.push("/signin");
          break;
        case 500:
          enqueueSnackbar(`Internal Server Error: ${dataType}`, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        default:
          console.log(`Error while fetching the ${dataType}`, error);
          enqueueSnackbar(`Error while fetching the ${dataType}`, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
      }
    } else {
      console.log(`Error while fetching the ${dataType}`, error);
      enqueueSnackbar(`Error while fetching the ${dataType}`, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    }
  };

  const sendChatMessage = async (content) => {
    if (currentlyStreaming) {
      enqueueSnackbar("Please wait until the current message is completed.", {
        variant: "warning",
      });
      return;
    }
    if (content.length === 0) {
      enqueueSnackbar("Message cannot be empty", { variant: "warning" });
      return;
    }
    setLoadingState((prevState) => ({ ...prevState, chatData: true }));

    setIsPreTemplate(true);
    const newMessageStrings = [];

    let lastAiMessage = "";
    let lastUserMessage = "";

    for (let i = userMessages.length - 1; i >= 0; i--) {
      const message = userMessages[i];
      if (message.role === "user" && !lastUserMessage) {
        lastUserMessage = message.content || " ";
      } else if (message.role === "ai" && !lastAiMessage) {
        lastAiMessage = message.content;
      }

      if (lastAiMessage && lastUserMessage) {
        break;
      }
    }

    if (userMessages.length > 1) {
      newMessageStrings.push(lastUserMessage);
      newMessageStrings.push(lastAiMessage);
      newMessageStrings.push(content);
    } else {
      newMessageStrings.push(lastAiMessage);
      newMessageStrings.push(content);
    }

    setUserMessages((prevUserMessages) => [
      ...prevUserMessages,
      { role: "user", content: content },
    ]);
    setMessage("");

    const reqData = {
      message: isPreTemplate ? content : newMessageStrings.join(""),
    };

    if (activeIndex !== -1) {
      reqData.conversation_id = activeIndex;
    }

    try {
      const res = await invService.postChatbotNew(reqData);

      if (res.status === 200 && res.data) {
        const newId = res.data.id;
        if (newId) {
          setActiveIndex(newId);
        }
        const aiMessage = res.data.messages[0].bot_message;

        streamAiMessage(aiMessage);
        // setUserMessages((prevUserMessages) => [
        //   ...prevUserMessages,
        //   { role: "ai", content: res.data.messages[0].bot_message },
        // ]);
      } else if (res?.data?.length <= 0) {
        enqueueSnackbar("No data available,", {
          variant: "warning",
        });
      }
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));
      handleFetchError(err, "No chats  data found");
    }
  };
  const handleChatHistory = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, chatHistory: true }));

      const res = await invService.getChatHistory();
      if (res.status === 200) {
        setHistoryList(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, chatHistory: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, chatHistory: false }));
      handleFetchError(error, "history data");
    }
  };
  const handleSingleChatHistory = async (chatId) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, singleChat: true }));
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));

      const res = await invService.getSingleChatHistory(chatId);
      if (res.status === 200) {
        setIsPreTemplate(true);
        setIsNewChat(false);
        const formattedMessages = res.data.messages.map((msg) => ({
          id: res.data.id,
          role: msg.sender === "bot" ? "ai" : "user",
          content: msg.message.replace(/^\nUser: /, ""),
        }));

        setUserMessages(formattedMessages);
      }
      setLoadingState((prevState) => ({ ...prevState, singleChat: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, singleChat: false }));
      handleFetchError(error, "this chat has no data");
    }
  };
// const streamAiMessage = (aiMessage) => {
//     const words = aiMessage.split(" ");
//     let wordIndex = 0;
//     let accumulatedMessage = "";
  
//     const updateInterval = 50;
//     const maxInterval = words.length * updateInterval;
//     let currentInterval = 0;
  
//     setCurrentlyStreaming(true);
  
//     const interval = setInterval(() => {
//       setUserMessages((prevUserMessages) => {
//         const lastMessageIndex = prevUserMessages.length - 1;
//         const lastMessage = lastMessageIndex >= 0 ? prevUserMessages[lastMessageIndex] : null;
  
//         if (!lastMessage || lastMessage.role !== "ai") {
//           return [
//             ...prevUserMessages,
//             { role: "ai", content: accumulatedMessage },
//           ];
//         }
  
//         if (wordIndex < words.length) {
//           accumulatedMessage += (accumulatedMessage ? " " : "") + words[wordIndex];
//           wordIndex += 1;
//         }
  
//         const updatedMessages = [...prevUserMessages];
//         updatedMessages[lastMessageIndex] = {
//           ...lastMessage,
//           content: accumulatedMessage,
//         };
//         
  
//         // If this is the last word, update the state with the final message before clearing the interval
//         if (wordIndex >= words.length) {
//           clearInterval(interval);
//           setCurrentlyStreaming(false);
  
//           // Ensure the last word is added
//           updatedMessages[lastMessageIndex] = {
//             ...lastMessage,
//             content: accumulatedMessage,
//           };
//         }
  
//         return updatedMessages;
//       });
  
//       currentInterval += updateInterval;
  
//       // Safeguard: clear the interval if it runs for too long
//       if (currentInterval >= maxInterval) {
//         clearInterval(interval);
//         setCurrentlyStreaming(false);
//       }
//     }, updateInterval);
//   };
  
const streamAiMessage = (aiMessage) => {
  
  // Add an extra space to the message to ensure spacing is handled correctly
  const words = (aiMessage + " ").split(" ");
  let wordIndex = 0;
  let accumulatedMessage = "";

  const updateInterval = 50;
  const maxInterval = words.length * updateInterval;
  let currentInterval = 0;

  setCurrentlyStreaming(true);

  const interval = setInterval(() => {
    setUserMessages((prevUserMessages) => {
      const lastMessageIndex = prevUserMessages.length - 1;
      const lastMessage = lastMessageIndex >= 0 ? prevUserMessages[lastMessageIndex] : null;

      // If no AI message exists, initialize the first AI message
      if (!lastMessage || lastMessage.role !== "ai") {
        return [
          ...prevUserMessages,
          { role: "ai", content: accumulatedMessage },
        ];
      }

      // Continue accumulating words
      if (wordIndex < words.length - 1) {
        accumulatedMessage += (accumulatedMessage ? " " : "") + words[wordIndex];
        wordIndex += 1;
      }

      const updatedMessages = [...prevUserMessages];
      updatedMessages[lastMessageIndex] = {
        ...lastMessage,
        content: accumulatedMessage,
      };

      // Log to help debug

      // If we've reached the last word, ensure the interval is cleared and streaming is stopped
      if (wordIndex >= words.length - 1) {
        clearInterval(interval);
        setCurrentlyStreaming(false);

        // Ensure the last word is added correctly
        updatedMessages[lastMessageIndex] = {
          ...lastMessage,
          content: accumulatedMessage.trim(),
        };
      }

      return updatedMessages;
    });

    currentInterval += updateInterval;

    // Safeguard: clear the interval if it runs for too long
    if (currentInterval >= maxInterval) {
      clearInterval(interval);
      setCurrentlyStreaming(false);
    }
  }, updateInterval);
};


  const handleNewChat = () => {
    setIsNewChat(true);
    setMessage("");
    setIsPreTemplate(false);
    setActiveIndex(-1);
    setUserMessages([]);
    handleChatHistory();
  };
  const handleEditClick = (content) => {
    setMessage(content);
  };

  return (
    <div className="home-container-chatbot">
      <div className="chat-sidebar">
        <div className="history_one">
          <p className="border-right"></p>
          <div className="history_one_text">
            <p className="Poppins_24px_black">History</p>
            <Tooltip title="New Chat" arrow>
              <LibraryAddIcon
                className="msg-send-chatbot"
                onClick={handleNewChat}
                style={{ color: "#00529A" }}
              />
            </Tooltip>
          </div>
        </div>

        <div className="history_two">
          {historyList.length > 0 ? (
            historyList.map((item, index) => {
              return (
                <div
                  key={item.id}
                  onClick={() => handleHistoryClick(item.id)}
                  className="history-div"
                  style={{
                    backgroundColor:
                      item.id === activeIndex ? "#00529A80" : "transparent",
                  }}
                >
                  <div className="space-btn-gap">
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg"
                      alt="message"
                      className="image-div-chat"
                    />
                    <div className="scroll-container">
                      <p className="Poppins_16px_400 history-title">
                        {item.title}
                      </p>
                    </div>
                  </div>
                  <MoreHorizIcon
                    onClick={handleHistoryDotsClick}
                    sx={{ color: item.id === activeIndex ? "#fff" : "#0000" }}
                  />
                </div>
              );
            })
          ) : (
            <p className="Poppins_16px_400 history-title">
              No recent history found
            </p>
          )}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "left",
              horizontal: "center",
            }}
            PaperProps={{ className: "customPopoverPaper" }}
          >
            <MenuItem
              onClick={handleHistoryPopoverClose}
              className="popover-menu"
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/v2Images/share_icon.svg"
                alt="share"
              />
              <p className="Poppins_14px_regular">Share Chat</p>
            </MenuItem>
            <MenuItem
              onClick={handleHistoryPopoverClose}
              className="popover-menu"
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/v2Images/rename_icon.svg"
                alt="share"
              />
              <p className="Poppins_14px_regular">Rename</p>
            </MenuItem>
            <MenuItem
              onClick={handleHistoryPopoverClose}
              className="popover-menu"
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/v2Images/delete_icon.svg"
                alt="share"
              />
              <p className="Poppins_14px_regular">Delete Chat</p>
            </MenuItem>
          </Popover>
        </div>
        {loadingState.chatHistory && (
          <SimpleBackdrop open={loadingState.chatHistory} />
        )}
      </div>

      <div className="handleclose-div">
        <div onClick={handleCloseBox} className="handleclose-subdiv">
          <p className="Poppins_15px_black" style={{ color: "#2F67F8" }}>
            Close
          </p>
        </div>
        {!isPreTemplate ? (
          <>
            <div className="chat-middle-div">
              <div className="chat-gif-div">
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/videos/chatbot_GIF.gif"
                  alt="chat-gif"
                  style={{ width: "12rem" }}
                />
                <h5 className="Poppins_24px_black">Welcome to CosmicGPT</h5>
                <p className="Poppins_16px_400">
                  Ask me anything about your data like status, past
                </p>
                <p className="Poppins_16px_400">
                  insights, even future predictions also.
                </p>
              </div>
            </div>
            <div className="search-div-chatbot">
              {searchedTabs.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleSerchedQue(item, index)}
                    className="search-div-two"
                    style={{
                      backgroundColor:
                        // activeSearch === index ? "#D9EAE4" :
                        "#ffff",
                      border:
                        // activeSearch === index ? "0.0625rem solid #B1D3C8" :
                        "0.0625rem solid #C3AAFA",
                    }}
                  >
                    <div className="smb_value_container">
                      <p className="Poppins_15px_black">{item.title}</p>
                      <p
                        className="Poppins_10px_rem"
                        style={{ fontWeight: "400", color: "black" }}
                      >
                        {item.question}
                      </p>
                    </div>
                    <div
                      className="arrow-div"
                      style={{
                        backgroundColor:
                          // activeSearch === index ? "#E6F1ED" :
                          "#B1D3C8",
                      }}
                    >
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/up_arrow_icon.svg"
                        alt="arrow"
                        style={{ width: "1rem" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div
              style={styles.chatWindow}
              className="msgview"
              ref={chatWindowRef}
            >
              {userMessages
                .filter((message) => message.content !== "")
                .map((message, index) => (
                  <div key={index}>
                    {message.role === "user" ? (
                      <div style={styles.messageDiv}>
                        <div className="editChat">
                          <EditIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEditClick(message.content);
                            }}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={styles.userMessage}>
                            <p className="chatText" id="usermsg">
                              <ReactMarkdown>{message.content}</ReactMarkdown>{" "}
                            </p>
                          </div>
                          <div></div>
                        </div>
                        <img
                          src="https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg"
                          alt="User Profile"
                          style={styles.profileImage}
                        />
                      </div>
                    ) : (
                      <div style={styles.userDiv}>
                        <img
                          src="https://s3.amazonaws.com/smarttrak.co/v2Images/botChat.svg"
                          alt="User Profile"
                          style={styles.profileImage}
                        />
                        <div style={styles.aiMessage}>
                          <p className="chatText">
                            <ReactMarkdown>
                              {message.content}
                            </ReactMarkdown>
                          </p>
                          <TextToSpeech
                            markdownText={message.content}
                            key={index}
                            id={index}
                            isCurrentPlaying={currentPlayingId === index}
                            onPlayStop={handlePlayStop}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {(loadingState.chatData || message.id === activeIndex) && (
                <div style={styles.aiMessage}>
                  <p className="loader"></p>
                </div>
              )}
            </div>
            {loadingState.singleChat && (
              <SimpleBackdrop open={loadingState.singleChat} />
            )}
          </>
        )}

        <div className="main-message-box">
          <div style={{ width: "100%", display: "flex" }}>
            <TextField
              className="message-box"
              id="text-chat"
              value={message}
              onChange={handleMessageChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  if (currentlyStreaming) {
                    enqueueSnackbar(
                      "Please wait until the current message is completed.",
                      { variant: "warning" }
                    );
                    e.preventDefault();
                    return;
                  }
                  sendChatMessage(e.target.value);
                  e.target.value = "";
                  e.preventDefault();
                }
              }}
              autoComplete="off"
              InputProps={{
                endAdornment: message ? (
                  <div
                    onClick={() => sendChatMessage(message)}
                    className="send-arrow arrow-bg"
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/up_arrow_icon.svg"
                      alt="arrow"
                    />
                  </div>
                ) : (
                  <div
                    disabled
                    className="send-arrow"
                    style={{
                      backgroundColor: "#E6F1ED",
                      cursor: "no-drop",
                      opacity: 0.5,
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/up_arrow_icon.svg"
                      alt="arrow"
                    />
                  </div>
                ),
                classes: { input: "Poppins_16px_400" },
              }}
              placeholder="Ask IQ........"
              fullWidth
            />
          </div>
          {/* <div className="refresh-box">
            <img
              onClick={() => setMessage("")}
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/refresh_icon.svg"
              alt="refresh"
              className="msg-send-chatbot"
            />
          </div> */}
          {/* <div className="refresh-box">
            {message ? (
              <SendRoundedIcon
                className="msg-send-chatbot"
                onClick={() => sendChatMessage(message)}
                style={{
                  color: "green",
                  cursor: "pointer",
                }}
              />
            ) : (
              <SendRoundedIcon
                disabled
                className="msg-send-chatbot"
                style={{
                  color: "gray",
                  cursor: "no-drop",
                }}
              />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
